.content{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;

    h3{
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        color: #003CA6;
        font-size: 48px;
        line-height: 1.60em;
        margin: 15px 0;
    }
    p{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: #1D3560;
        font-size: 18px;
        line-height: 1.50em;
        margin: 0;
    }


    .contentSubtitulo{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        grid-gap: 14px;

        p{
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            text-transform: uppercase;
            color: #00B2E2;
            font-size: 20px;
            line-height: 1.50em;
            margin: 0;
        }

        .divAzul{
            width: 85px;
            height: 7px;
            background-color: #00B2E2;
            border-radius: 10px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .content{
        width: 80%;
    }
    

}
@media screen and (max-width: 900px) {
    .content{
        

        h3{
            font-size: 38px;
        }

        .contentSubtitulo{
            p{
                font-size: 18px;
            }
        }
    }
    

}
@media screen and (max-width: 500px) {
    .content {

        h3{
            font-size: 28px;
        }
        p{
            font-size: 15px;
        }

      .contentSubtitulo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        grid-gap: 14px;
        width: 100%;

        p{
            font-size: 15px;
        }
        .divAzul{
            width: 15%;
            height: 4px;
        }
      }
    }

}
