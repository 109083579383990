.Header{
    min-height: 118px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .logo{
        width: 34%;
    }
}
@media screen and (max-width: 680px) {
    .Header{
        min-height: 92px;
        .logo{
            width: 55%;
        }
    }

}
