.Calculadora{
    background-color: #fff;
    position: relative;
    width: 68%;
    max-width: 1038px;
    min-height: 616px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    padding: 25px;
    margin: 52px 0;
    box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.2);

    h2{
        color: #0B2F70;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 33px;
        line-height: 1.60em;
    }
    .LogoSimple{
        position: absolute;
        right: 35px;
        bottom: 32px;
    }

    .containerWrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        grid-gap: 15px;
        max-width: 100%;
        padding-top: 20px;


        button{
            padding: 18px; 
            border-radius: 16.52px;
            border: 1px solid #AEAEAE;
            display: flex;
            align-items: center;
            color: #AEAEAE;
            background-color: #fff;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.50em;
        }

        button:hover,
        button.activeButton {
          color: #fff;
          box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.2);
          background-color: #00B2E3;
          cursor: pointer;
        }
        
        button:hover svg path,
        button:active svg path {
          fill: #fff !important;
           border-radius: 16.52px;
        }
        svg {
            margin: 0 6px;
        }
    }

}

@media screen and (max-width: 1110px) {
    .Calculadora{
        .containerWrap{
            flex-wrap: wrap;
        }
    }
    .Formulario{
        label{
            text-align: left !important;
        }
    }
}
@media screen and (max-width: 600px) {
    .Calculadora{
        width: 88%;
        padding-top: 35px;

       h2{
        font-size: 26px;
       }
       .containerWrap{
        button{
            padding: 12px;
            font-size: 14px;
        }
       }
       .LogoSimple{
        right: 25px;
        bottom: 26px;
        width: 11%;
    }
    }
    .Formulario{
      width: 90% !important;
      
        

        label{
            font-size: 14px;
            text-align: center !important;
        }
        input{
            font-size: 14px !important;
            width: 100% !important;
            padding: 12px 10px !important;
            margin: 8px;
        }
        div{
            display: flex !important;
            justify-content: center !important;
            align-content: space-between !important;
            flex-direction: column !important;
            align-items: center;
            padding: 10px 0;
        }
        button{
            width: 78% !important;
            margin-top: 45px !important;
            margin-bottom: 20px !important;
        }
    }
}


.Formulario{
    margin: 38px 0;
    width: 85%;
    svg {
        margin: 0 10px;
      }

    label{
        color: #1D3560;
        background-color: #fff;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.50em; 
    }
    input{
        color: #8C8C8C;
        background-color: #fff;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.50em; 
        border-radius: 16.52px;
        border: 1px solid #AEAEAE;
        width: 48%;
        text-align: center;
        padding: 15px 0;
    }
    div{
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-direction: row;
        align-items: center;
        padding: 10px 0;
    }

    button{
        padding: 18px; 
        border-radius: 16.52px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: #fff;
        background-color: #79DAD3;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 16.5px;
        line-height: 1.50em;
        width: 52%;
        margin: auto;
        margin-top: 60px;
    }

    button:hover{
        box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.2);
        background-color: #00B2E3;
        cursor: pointer;
    }

}

.ContentFormulario{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}