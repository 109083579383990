.App {
  text-align: center;
}
.CalculadoraPage{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.background {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 816px;
}